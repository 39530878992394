<template>
    <v-container fluid>
        <v-row row wrap>
            <template v-if="loading">
                <v-col cols="12" class="text-center" mt-4>
                <v-progress-circular
                    :width="3"
                    color="blue"
                    indeterminate
                ></v-progress-circular>
                </v-col>
            </template>
            <template v-else>
                <v-col cols="7">
                    <v-alert
                    dense dismissable
                    outlined color="primary"
                    >
                    Includes all products in Draft and Active status. Access archives by increasing archive days and reload
                    </v-alert>
                </v-col>
                <v-col cols="4">
                    <v-text-field
                        v-model="daysOfArchive"
                        label="Archive Days"
                        hint="For example, 7 archive days will pull products with pickup deadlines up to 7 days ago"
                        type="number"
                        />
                </v-col>
                <v-col cols="1" mt-3>
                    <div class="text-center">
                        <v-btn icon color="primary" @click="refresh" :loading="loading" :disabled="loading">
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </div>
                </v-col>



                <v-col cols="12">
                    <v-tabs v-model="tab" grow>

                        <v-tab :disabled="draftProducts.length==0" key="Draft">Draft</v-tab>
                        <v-tab-item>
                            <DashboardSection
                            :products="draftProducts" 
                            :hideDateDetail="false"
                            />
                        </v-tab-item>

                        <v-tab :disabled="activeProducts.length==0" key="Active">Active</v-tab>
                        <v-tab-item>
                            <DashboardSection 
                            :products="activeProducts" 
                            :hideDateDetail="false"
                            />
                        </v-tab-item>

                        <v-tab :disabled="expiredProducts.length==0" key="Archive">Archive</v-tab>
                        <v-tab-item>
                            <DashboardSection 
                            :products="expiredProducts" 
                            :hideDateDetail="true"
                            />
                        </v-tab-item>
                    </v-tabs>
                </v-col>

                <v-col cols="12" mt-3>
                    <div class="text-center">
                         <v-btn @click="startImport()" :loading="loading" outlined color="primary">Import Products</v-btn>
                    </div>
                </v-col>
                <v-dialog v-model="confirmImport" persistent max-width="500">
                        <v-card>
                            <v-card-title>
                                Import products from Brewer.ai
                            </v-card-title>
                            <v-spacer />
                            <template v-if="baiLoading">
                                <v-col cols="12" class="text-center" mt-4>
                                <v-progress-circular
                                    :width="3"
                                    color="blue"
                                    indeterminate
                                ></v-progress-circular>
                                </v-col>
                            </template>
                            <template v-else>
                                <template v-if="importableProducts.length>0">

                                    <v-card-subtitle>
                                        Select the products below to be imported into botls. If a product is already in botls, importing will overwrite the product details in botls with those in brewer-ai.
                                    </v-card-subtitle>
                                    <v-card-text>
                                        <v-list>
                                        <v-list-item v-for="(item, index) in importableProducts" :key="index">
                                            <v-checkbox v-model="productsToImport" :value="item" />
                                            {{ item.productId }}
                                        </v-list-item>
                                        </v-list>
                                    </v-card-text>
                                </template>
                                <template v-else>
                                    <v-card-subtitle>
                                        No products to import. Please check brewer.ai to ensure product(s) have completed release information and correct dates.
                                    </v-card-subtitle>
                                </template>
                                <v-card-actions>
                                    <v-btn outlined :disabled="productsToImport.length == 0" color="primary" @click="importSelectedProducts()">Import Selected</v-btn>
                                    <v-spacer />
                                    <v-btn outlined color="light" text @click="confirmImport = false">Cancel</v-btn>
                                </v-card-actions>
                            </template>
                        </v-card>
                </v-dialog>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapMutations} from 'vuex'
import api from '@/store/api'
import DashboardSection from './DashboardSection'
import moment from 'moment-timezone'
export default {
    data () {
        return {
            pagination: {'sortBy': 'casesRemaining', 'descending': true, 'rowsPerPage': 8},
            selected: [],
            fetchArchive: false,
            headers: [
                { text: 'Name', align: 'left', sortable: false, value: 'product'},
                { text: 'Release Date', align: 'center', sortable: true, value: 'releaseDate' , class: "hidden-xs-only"},
                { text: 'Cases Ordered', align: 'center', sortable: true, value: 'casesOrdered' , class: "hidden-xs-only"},
                { text: 'Cases Owed', value: 'casesRemaining', sortable: true, align: 'right'},
            ],
            tab: 0,
            confirmImport: false,
            daysOfArchive: 0,
            importableProducts: [],
            productsToImport: [],
            baiLoading: false,
        }
    },
    components: {
        DashboardSection
    },
    created(){
        console.log("created")
    },
    mounted () {
       this.refresh()
    },
    computed: {
        ...mapGetters ([
            'loading'
        ]),
        products () {
            return this.$store.state.adminDashboard
        },
        draftProducts () {
            return this.$store.state.adminDashboard.filter(product => this.isDraft(product.status))
        },
        activeProducts () {
            return this.$store.state.adminDashboard.filter(product => !this.isDraft(product.status) && !this.expiredDate(product.pickupEnd) )
        },
        expiredProducts () {
            return this.$store.state.adminDashboard.filter(product => this.expiredDate(product.pickupEnd))
        },
        pages () {
        if (this.pagination.rowsPerPage == null ||
          this.pagination.totalItems == null
        ) return 0
        return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
      }
    },
    methods: {
        ...mapMutations ([
            'setLoading'
        ]),
        shortDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").calendar()
        },
        lastDay (dateStr) {
            let diff = moment(dateStr, "YYYY-MM-DD @HH:mm").diff(moment(), "hours")
            return diff < 24
        },
        isDraft (status) {
            if(status == undefined || status == null)
                return false
            if(status == "Draft")
                return true
            return false
        },
        expiredDate (dateStr) {
            let deadline = moment(dateStr, "YYYY-MM-DD @HH:mm")
            let hoursIntoPast = moment().diff(deadline, 'hours')
            return (hoursIntoPast >= 0)
        },
        compare(a, b) {
            if (moment(a.releaseDate, "YYYY-MM-DD @HH:mm").isBefore(moment(b.releaseDate, "YYYY-MM-DD @HH:mm")))
                return 1
            else return -1
        },
       async refresh ( ) {
            let self = this
            self.setLoading(true)
            await api.getOrderDashboard(this.daysOfArchive)
            self.tab = 0
            if(self.draftProducts.length==0) self.tab = 1
            if(self.activeProducts.length==0) self.tab = 2
            self.setLoading(false)
        },
        async startImport(){
            this.confirmImport = true
            await this.getImportableProducts()
        },
        async getImportableProducts () {
            this.baiLoading = true
            let uid = this.$store.state.user.uid
            var functionsCall = global.FIREBASE.functions.httpsCallable('getAvailableProductsFromBrewerAi')
            let response = await functionsCall({uid: uid,})
            if(response.data.status == "success"){
                this.importableProducts = response.data.products
            }    
            else{
                console.log(response.data.error)
            }      
            this.baiLoading = false
        },
        async importSelectedProducts () {
            this.setLoading(true)
            this.confirmImport = false
            let uid = this.$store.state.user.uid
            var functionsCall = global.FIREBASE.functions.httpsCallable('importProductsFromBrewerAi')
            let response = await functionsCall({uid: uid, products: this.productsToImport})
            if(response.data.status == "success"){
                await this.refresh()
            }    
            else{
                console.log(response.data.error)
            }      
            this.setLoading(false)
        },
    }
}
</script>
